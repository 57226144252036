import React from "react";
import { Graph } from "react-d3-graph";  // 必要なインポート

// グラフのデータを定義
const data = {
  nodes: [
    { id: "DDP Root" },
    { id: "Subgoal 1" },
    { id: "Subgoal 2" }
  ],
  links: [
    { source: "DDP Root", target: "Subgoal 1" },
    { source: "DDP Root", target: "Subgoal 2" }
  ]
};

const onClickNode = (nodeId) => {
  console.log(`Clicked node ${nodeId}`);
};

const onClickLink = (source, target) => {
  console.log(`Clicked link between ${source} and ${target}`);
};

const myConfig = {
  nodeHighlightBehavior: true,
  node: {
    color: "lightblue",
    size: 1200,
    fontSize: 16,
    highlightStrokeColor: "blue",
  },
  link: {
    highlightColor: "blue",
  },
  panAndZoom: false, // ズームとパンを無効化
  directed: true,
};

const MindMap = () => (
  <div style={{ height: "500px" }}>
    <Graph
      id="mind-map"
      data={data}  // dataを指定
      config={myConfig}
      onClickNode={onClickNode} // ノードクリック時のイベント
      onClickLink={onClickLink} // リンククリック時のイベント
    />
  </div>
);

export default MindMap;
