import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  addDoc,
  serverTimestamp,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import GroupIcon from "@mui/icons-material/Group";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatIcon from "@mui/icons-material/Chat";
import MovieIcon from "@mui/icons-material/Movie";
import GroupMembers from "./GroupMembers";
import DDPSetting from "./DDPSetting";
import GroupChatEx from "./GroupChatEx";
import Webinar from "./Webinar";
import PrefectureChat from "./PrefectureChat";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Tooltip,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fab,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Checkbox,
  FormGroup,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import SurveyIcon from "@mui/icons-material/QuestionAnswer";

import SophieMission from "./SophieMission";

const Group = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(0);
  const [user, setUser] = useState(null);
  const [isMissionDrawerOpen, setMissionDrawerOpen] = useState(false);
  const [isSurveyDialogOpen, setSurveyDialogOpen] = useState(false);
  const [isFabVisible, setFabVisible] = useState(true);
  
  const [surveyAnswers, setSurveyAnswers] = useState({
    chatPresence: "", // チャット有無
    activityNumber: "", // 活動人数
    missions: [], // グループで取り組めたミッション（複数選択）
    ddpAchieved: "", // 既にDDPが叶ったか（はい/いいえ）
    participatePrefectureActivities: "", // 新規追加: 都道府県の活動に参加していますか？（はい/いいえ）
    mastermind2ndParticipation: "", // マスターマインドメンバーズ第2期の参加希望（はい/いいえ）
    mastermind2ndContent: [], // 参加希望内容（複数選択）
    mastermind2ndContentOther: "", // その他の内容
    mastermind2ndReason: "", // 新規追加: プログラム希望理由
  }); 
  
  const [isMastermind2ndContentOtherChecked, setIsMastermind2ndContentOtherChecked] = useState(false);
  const [isMastermind2ndParticipationYes, setIsMastermind2ndParticipationYes] = useState(false);
  
  const [isThankYouDialogOpen, setThankYouDialogOpen] = useState(false);

  const surveyId = "202411Early";
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const topOffset = isSmallScreen ? "56px" : "64px";

  useEffect(() => {
    const checkSurveyResponse = async () => {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(db, "users", userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUser(userData);

          const userSurveyQuery = query(
            collection(db, "users", userId, "fab"),
            where("surveyId", "==", surveyId)
          );
          const userSurveySnap = await getDocs(userSurveyQuery);
          setFabVisible(userSurveySnap.empty);
        } else {
          console.error("User not found");
        }
      } catch (error) {
        console.error("Failed to fetch user", error);
      }
    };

    checkSurveyResponse();
  }, [surveyId]);

  useEffect(() => {
    const fetchGroup = async () => {
      if (!user) return;

      setLoading(true);
      try {
        if (!user.isAdmin && user.participatingGroup !== id) {
          navigate(`/group/${user.participatingGroup}`);
          return;
        }

        const docRef = doc(db, "groups", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setGroup(docSnap.data());
        } else {
          setError("Group not found");
        }
      } catch (err) {
        setError("Failed to fetch group");
      } finally {
        setLoading(false);
      }
    };

    fetchGroup();
  }, [id, user, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value]);

  const getPrefectureLogicalName = (prefecture) => {
    const prefectureMap = {
      神奈川県: "kanagawa",
      千葉県: "chiba",
      東京都: "tokyo",
      山梨県: "yamanashi",
      長野県: "nagano",
      三重県: "mie",
      静岡県: "shizuoka",
      鳥取県: "tottori",
      長崎県: "nagasaki",
      岐阜県: "giju",
      宮城県: "miyagi",
      兵庫県: "hyogo",
      島根県: "shimane",
      愛知県: "aichi",
      大阪府: "osaka",
      "海外（アメリカ）": "amerika",
      山形県: "yamagata",
      広島県: "hiroshima",
      "海外（その他）": "foreign",
      福岡県: "fukuoka",
      香川県: "kagawa",
      沖縄県: "okinawa",
      北海道: "hokkaido",
      岩手県: "iwate",
      大分県: "oita",
      石川県: "ishikawa",
      茨城県: "ibaraki",
      京都府: "kyoto",
      秋田県: "akita",
      埼玉県: "saitama",
      新潟県: "nigata",
      佐賀県: "saga",
      福島県: "fukushima",
      宮崎県: "miyazaki",
      鹿児島県: "kagoshima",
      奈良県: "nara",
      熊本県: "kumamoto",
      高知県: "kochi",
      富山県: "toyama",
      岡山県: "okayama",
      滋賀県: "shiga",
      福井県: "fukui",
      群馬県: "gunma",
      山口県: "yamaguchi",
      栃木県: "tochigi",
      青森県: "aomori",
      徳島県: "tokushima",
      愛媛県: "ehime",
      和歌山県: "wakayama",
    };
    return prefectureMap[prefecture] || prefecture;
  };

  const getPrefectureNameWithoutSuffix = (prefecture) => {
    const prefectureMap = {
      神奈川県: "神奈川",
      千葉県: "千葉",
      東京都: "東京",
      山梨県: "山梨",
      長野県: "長野",
      三重県: "三重",
      静岡県: "静岡",
      鳥取県: "鳥取",
      長崎県: "長崎",
      岐阜県: "岐阜",
      宮城県: "宮城",
      兵庫県: "兵庫",
      島根県: "島根",
      愛知県: "愛知",
      大阪府: "大阪",
      "海外（アメリカ）": "アメリカ",
      山形県: "山形",
      広島県: "広島",
      "海外（その他）": "海外",
      福岡県: "福岡",
      香川県: "香川",
      沖縄県: "沖縄",
      北海道: "北海道",
      岩手県: "岩手",
      大分県: "大分",
      石川県: "石川",
      茨城県: "茨城",
      京都府: "京都",
      秋田県: "秋田",
      埼玉県: "埼玉",
      新潟県: "新潟",
      佐賀県: "佐賀",
      福島県: "福島",
      宮崎県: "宮崎",
      鹿児島県: "鹿児島",
      奈良県: "奈良",
      熊本県: "熊本",
      高知県: "高知",
      富山県: "富山",
      岡山県: "岡山",
      滋賀県: "滋賀",
      福井県: "福井",
      群馬県: "群馬",
      山口県: "山口",
      栃木県: "栃木",
      青森県: "青森",
      徳島県: "徳島",
      愛媛県: "愛媛",
      和歌山県: "和歌山",
    };
    return prefectureMap[prefecture] || prefecture;
  };

  const handleMissionClick = () => {
    setMissionDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setMissionDrawerOpen(false);
  };

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 2) {
      setFabVisible(false);
    } else {
      if (surveyId && isFabVisible) {
        setFabVisible(true);
      }
    }
  };

  const handleSurveyClick = async () => {
    if (!user) return;

    try {
      const userSurveyQuery = query(
        collection(db, "users", user.uid, "fab"),
        where("surveyId", "==", surveyId)
      );
      const userSurveySnap = await getDocs(userSurveyQuery);

      if (userSurveySnap.empty) {
        setSurveyDialogOpen(true);
      } else {
        setFabVisible(false);
        console.log("ユーザーはすでにアンケートに回答済みです");
      }
    } catch (error) {
      console.error("アンケート回答のチェックに失敗しました", error);
    }
  };

  const handleSurveyClose = () => {
    setSurveyDialogOpen(false);
  };

  const handleSurveyAnswerChange = (event) => {
    const { name, value, type, checked } = event.target;
  
    if (name === "missions" || name === "mastermind2ndContent") {
      // 複数選択の処理
      let updatedArray = [];
      if (name === "missions") {
        updatedArray = [...surveyAnswers.missions];
        if (checked) {
          updatedArray.push(value);
        } else {
          updatedArray = updatedArray.filter((item) => item !== value);
        }
        setSurveyAnswers((prev) => ({
          ...prev,
          missions: updatedArray,
        }));
      } else if (name === "mastermind2ndContent") {
        updatedArray = [...surveyAnswers.mastermind2ndContent];
        if (checked) {
          updatedArray.push(value);
          if (value === "other") {
            setIsMastermind2ndContentOtherChecked(true);
          }
        } else {
          updatedArray = updatedArray.filter((item) => item !== value);
          if (value === "other") {
            setIsMastermind2ndContentOtherChecked(false);
            setSurveyAnswers((prev) => ({
              ...prev,
              mastermind2ndContentOther: "",
            }));
          }
        }
        setSurveyAnswers((prev) => ({
          ...prev,
          mastermind2ndContent: updatedArray,
        }));
      }
    } else if (name === "ddpAchieved") {
      setSurveyAnswers((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (name === "participatePrefectureActivities") {
      setSurveyAnswers((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (name === "mastermind2ndParticipation") {
      setSurveyAnswers((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (value === "はい") {
        setIsMastermind2ndParticipationYes(true);
      } else {
        setIsMastermind2ndParticipationYes(false);
        // Reset mastermind2ndContent and reason if "いいえ" is selected
        setSurveyAnswers((prev) => ({
          ...prev,
          mastermind2ndContent: [],
          mastermind2ndContentOther: "",
          mastermind2ndReason: "",
        }));
        setIsMastermind2ndContentOtherChecked(false);
      }
    } else if (name === "mastermind2ndContentOther") {
      setSurveyAnswers((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (name === "mastermind2ndReason") {
      setSurveyAnswers((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setSurveyAnswers((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSaveSurvey = async () => {
    if (!surveyAnswers || !user || !group) return;

    // バリデーション（必要に応じて追加）
    // 例: 必須項目のチェック
    if (
      !surveyAnswers.activityNumber ||
      !surveyAnswers.ddpAchieved ||
      !surveyAnswers.participatePrefectureActivities ||
      !surveyAnswers.mastermind2ndParticipation
    ) {
      alert("すべての必須項目に回答してください。");
      return;
    }

    if (
      surveyAnswers.mastermind2ndParticipation === "はい" &&
      !surveyAnswers.mastermind2ndReason
    ) {
      alert("マスターマインドメンバーズ第2期を希望する理由を記載してください。");
      return;
    }

    const surveyData = {
      createdAt: serverTimestamp(),
      surveyId,
      answers: { ...surveyAnswers },
      userId: user.uid,
      groupId: id,
    };

    try {
      const userFabRef = doc(db, "users", user.uid, "fab", surveyId);
      await setDoc(userFabRef, surveyData);

      const fabRef = collection(db, "fab");
      await addDoc(fabRef, surveyData);

      console.log(
        "アンケートが`/users/userId/fab`と`/fab`コレクションに保存されました"
      );

      setSurveyDialogOpen(false);
      setThankYouDialogOpen(true);
    } catch (error) {
      console.error("アンケートの保存に失敗しました", error);
    }
  };

  const handleThankYouDialogClose = () => {
    setThankYouDialogOpen(false);
  };

  const handleCloseFab = () => {
    setFabVisible(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", maxHeight: "100vh" }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleNavigationChange}
        style={{
          position: "fixed",
          top: topOffset,
          backgroundColor: "#ffffff",
          zIndex: 1000,
          maxWidth: "1152px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          overflow: "hidden",
          height: "56px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Tooltip title="仲間" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 0 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label="仲間"
            icon={<GroupIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        <Tooltip title="DDP" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 1 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label="DDP"
            icon={<FavoriteIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        <Tooltip title="Chat" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 2 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label="Chat"
            icon={<ChatIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        {/* <Tooltip title="設定" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 3 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label="設定"
            icon={<SettingsIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip> */}
        <Tooltip title="11/17" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 3 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label="11/17"
            icon={<MovieIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>

        <Tooltip title="ソフィーからのミッション" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 4 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            onClick={handleMissionClick}
            icon={<StarIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        <Tooltip title="居住チャット" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 6 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label={
              <Typography
                style={{
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {getPrefectureNameWithoutSuffix(user?.prefecture)}
              </Typography>
            }
            icon={<ChatIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
      </BottomNavigation>

      <Fab
        id="survey-fab"
        color="primary"
        aria-label="アンケート"
        onClick={handleSurveyClick}
        style={{
          position: "fixed",
          bottom: "80px",
          right: "16px",
          zIndex: 1100,
          display: isFabVisible ? "block" : "none",
        }}
      >
        <SurveyIcon />
      </Fab>

      <div style={{ flex: 1, marginTop: topOffset }}>
        {value === 0 && <GroupMembers group={group} />}
        {value === 1 && <DDPSetting />}
        {value === 2 && <GroupChatEx groupId={id} />}
        {/* {value === 3 && <Settings />} */}
        {value === 3 && <Webinar />}
        {value === 5 && (
          <PrefectureChat groupId={getPrefectureLogicalName(user?.prefecture)} />
        )}
      </div>

      <Drawer anchor="right" open={isMissionDrawerOpen} onClose={handleDrawerClose}>
        <SophieMission onClose={handleDrawerClose} />
      </Drawer>

      <Dialog open={isSurveyDialogOpen} onClose={handleSurveyClose} maxWidth="sm" fullWidth>
        <DialogTitle>アンケート</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            以下の質問にお答えください。
          </Typography>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="activity-number-label">
                グループでの実際の活動人数は？
              </InputLabel>
              <Select
                labelId="activity-number-label"
                name="activityNumber"
                value={surveyAnswers.activityNumber}
                onChange={handleSurveyAnswerChange}
                label="グループでの実際の活動人数は？"
              >
                {Array.from({ length: 10 }, (_, i) => (
                  <MenuItem key={i + 1} value={10 - i}>
                    {10 - i}人
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset" fullWidth>
              <Typography variant="subtitle2">
                グループで取り組めたミッションを教えてください。
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("onlineMeeting")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="onlineMeeting"
                    />
                  }
                  label="オンライン（Zoomなど）で顔合わせをした。"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("inPersonMeeting")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="inPersonMeeting"
                    />
                  }
                  label="実際に会ったことがある。"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("discussionTheme")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="discussionTheme"
                    />
                  }
                  label="お互いを知ることのできそうなテーマを考えて話すことができた。"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("jointActivity")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="jointActivity"
                    />
                  }
                  label="今参加しているメンバーで共通して取り組むことを実践した。"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("ddpPoints")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="ddpPoints"
                    />
                  }
                  label="DDPの作成のポイントについてグループメンバー内で共通認識が持てている。"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("deepDiveDDP")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="deepDiveDDP"
                    />
                  }
                  label="DDPの深堀をグループメンバーで行った。"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("chatGPTDDPReview")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="chatGPTDDPReview"
                    />
                  }
                  label="チャットGPTのDDP添削機能を利用した。"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("ddpConfirmed")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="ddpConfirmed"
                    />
                  }
                  label="冬至までのDDPを確定した。"
                />
                {/* 追加ミッション */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("commonCommitment")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="commonCommitment"
                    />
                  }
                  label="３０分の共通の取り組みを行い、メンバー同士で応援や励ましができている。"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("ddpBookCreation")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="ddpBookCreation"
                    />
                  }
                  label="「〇〇さんのDDPが叶う30の理由」の本を作成した。"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("precelebrationWork")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="precelebrationWork"
                    />
                  }
                  label="予祝のワークを行った。"
                />
              </FormGroup>
            </FormControl>
          </Box>

          {/* すでにDDPは叶いましたか？ */}
          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset" fullWidth>
              <Typography variant="subtitle2">
                すでにDDPは叶いましたか？
              </Typography>
              <RadioGroup
                aria-label="ddp-achieved"
                name="ddpAchieved"
                value={surveyAnswers.ddpAchieved}
                onChange={handleSurveyAnswerChange}
                row
              >
                <FormControlLabel
                  value="はい"
                  control={<Radio />}
                  label="はい"
                />
                <FormControlLabel
                  value="いいえ"
                  control={<Radio />}
                  label="いいえ"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {/* 「都道府県の活動に参加していますか？」 */}
          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset" fullWidth>
              <Typography variant="subtitle2">
                都道府県の活動に参加していますか？
              </Typography>
              <RadioGroup
                aria-label="participate-prefecture-activities"
                name="participatePrefectureActivities"
                value={surveyAnswers.participatePrefectureActivities}
                onChange={handleSurveyAnswerChange}
                row
              >
                <FormControlLabel value="はい" control={<Radio />} label="はい" />
                <FormControlLabel value="いいえ" control={<Radio />} label="いいえ" />
              </RadioGroup>
            </FormControl>
          </Box>

          {/* すべての質問の最後にマスターマインドメンバーズ第2期の参加希望を配置 */}
          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset" fullWidth>
              <Typography variant="subtitle2">
                マスターマインドメンバーズ第2期があれば参加したいですか？
              </Typography>
              <RadioGroup
                aria-label="mastermind2nd-participation"
                name="mastermind2ndParticipation"
                value={surveyAnswers.mastermind2ndParticipation}
                onChange={handleSurveyAnswerChange}
                row
              >
                <FormControlLabel value="はい" control={<Radio />} label="はい" />
                <FormControlLabel value="いいえ" control={<Radio />} label="いいえ" />
              </RadioGroup>
            </FormControl>
          </Box>

          {/* 「はい」を選択した場合の追加質問 */}
          {isMastermind2ndParticipationYes && (
            <>
              <Box sx={{ mt: 2 }}>
                <FormControl component="fieldset" fullWidth>
                  <Typography variant="subtitle2">
                    参加するとしたらどのような内容を希望しますか？
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surveyAnswers.mastermind2ndContent.includes("shiftPeriod")}
                          onChange={handleSurveyAnswerChange}
                          name="mastermind2ndContent"
                          value="shiftPeriod"
                        />
                      }
                      label="期間をずらし（例：2025年夏至まで）今回と同じミッションを行いたい。"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surveyAnswers.mastermind2ndContent.includes("deepenDDP")}
                          onChange={handleSurveyAnswerChange}
                          name="mastermind2ndContent"
                          value="deepenDDP"
                        />
                      }
                      label="DDPを深めることや、在りたい姿を明確にしたい。"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surveyAnswers.mastermind2ndContent.includes("useAIforDDP")}
                          onChange={handleSurveyAnswerChange}
                          name="mastermind2ndContent"
                          value="useAIforDDP"
                        />
                      }
                      label="チャットGPTのDDP添削のようなAIを用いてDDPを深めたい。"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surveyAnswers.mastermind2ndContent.includes("exchangeDDPProviders")}
                          onChange={handleSurveyAnswerChange}
                          name="mastermind2ndContent"
                          value="exchangeDDPProviders"
                        />
                      }
                      label="メンバー同士で、DDPの提供者（自信のできること）と交換希望を募り、交換会をやりたい。"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surveyAnswers.mastermind2ndContent.includes("other")}
                          onChange={handleSurveyAnswerChange}
                          name="mastermind2ndContent"
                          value="other"
                        />
                      }
                      label="その他。"
                    />
                  </FormGroup>
                </FormControl>
              </Box>

              {/* 「その他」がチェックされた場合のテキストボックス */}
              {isMastermind2ndContentOtherChecked && (
                <Box sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <TextField
                      label="その他の内容を記載してください。"
                      name="mastermind2ndContentOther"
                      value={surveyAnswers.mastermind2ndContentOther}
                      onChange={handleSurveyAnswerChange}
                      multiline
                      rows={3}
                    />
                  </FormControl>
                </Box>
              )}

              {/* 新規追加: なぜ、そのプログラムを希望するか理由を教えてください。 */}
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth>
                  <TextField
                    label="なぜ、そのプログラムを希望するか理由を教えてください。"
                    name="mastermind2ndReason"
                    value={surveyAnswers.mastermind2ndReason}
                    onChange={handleSurveyAnswerChange}
                    multiline
                    rows={3}
                  />
                </FormControl>
              </Box>
            </>
          )}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleSurveyClose} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={handleSaveSurvey}
            color="primary"
            variant="contained"
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isThankYouDialogOpen}
        onClose={handleThankYouDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>ありがとうございます</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            アンケートにご協力いただき、ありがとうございます。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleThankYouDialogClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Group;