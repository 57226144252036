import React from "react";
import { Tooltip } from "@mui/material";

const CreateHeaderMenu = ({ text, icon, onclick, active, complete }) => {
  const Icon = ({ Icon, onClick }) => {
    return <>{Icon && <Icon className="h-4 w-4" onClick={onClick} />}</>;
  };

  return (
    <>
      <Tooltip title={text} arrow>
        <div
          className={` 
          ${active && "bg-blue-500"}
          ${complete ? "text-gray-800" : "text-gray-200"}
           " flex flex-col justify-center items-center w-14 space-y-1 hover:cursor-pointer hover:text-blue-500 duration-150 rounded-full "`}
          onClick={onclick}
        >
          <Icon Icon={icon} />
          <div className="w-fit text-xs">{text}</div>
        </div>
      </Tooltip>
    </>
  );
};

export default CreateHeaderMenu;
