import React, { useState } from "react";
import { MessageInput } from "stream-chat-react";
import SendIcon from "@mui/icons-material/Send";

const CustomMessageInput = ({ overrideSubmitHandler }) => {
  const [key, setKey] = useState(0); // MessageInputのリセット用

  // 送信ボタンをクリックした際に送信する
  const handleSendButtonClick = () => {
    const messageInputElem = document.querySelector("textarea"); // MessageInput内のtextareaを取得
    const previewElements = document.querySelectorAll(".rfu-thumbnail__image"); // 添付画像要素を取得

    const message = {
      text: messageInputElem ? messageInputElem.value : "", // textareaの中の値を取得してメッセージに設定
      attachments: [], // 添付ファイル用の配列
    };

    // 添付画像が存在する場合、attachmentsに追加
    previewElements.forEach((previewElem) => {
      const imageUrl = previewElem.src; // 画像のURLを取得
      if (imageUrl) {
        message.attachments.push({
          image_url: imageUrl, // メッセージに画像URLを添付
          type: "image", // 添付タイプを画像として設定
        });
      }
    });

    overrideSubmitHandler(message); // メッセージ送信
    setKey((prevKey) => prevKey + 1); // 送信後にMessageInputをリセット
  };

  // カスタムのSendButton
  const CustomSendButton = () => (
    <SendIcon
      className="send-button"
      onClick={handleSendButtonClick} // アイコンをクリックして送信
      style={{
        cursor: "pointer",
        fontSize: "24px",
        color: "#006cff",
        background: "white",
      }}
    />
  );

  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%",background:"white" }}>
      <div style={{ flex: 1,  }}>
        <MessageInput
          key={key} // keyを使ってMessageInputを強制的に再レンダリング
          shouldSubmit={() => false} // エンターキーで送信しない
          hideSendButton={true} // デフォルトの送信ボタンを非表示に
        />
      </div>
      <CustomSendButton /> {/* カスタム送信ボタンを右側に表示 */}
    </div>
  );
};

export default CustomMessageInput;
