import React, { useState, useEffect } from "react";
import { db, auth } from "../api/firebase";
import {
  doc,
  onSnapshot,
  updateDoc,
  collection,
  query,
  addDoc,
  limit,
  getDocs,
  serverTimestamp,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Box,
  Modal,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import MuiAlert from "@mui/material/Alert";
import { CelebrationAnimation } from "./CelebrationAnimation";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DDPList = ({ userId }) => {
  const [ddp, setDdp] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editText, setEditText] = useState("");
  const [openSubgoalDialog, setOpenSubgoalDialog] = useState(false);
  const [subgoalText, setSubgoalText] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [celebrationMessage, setCelebrationMessage] = useState("");
  const [subgoalTitle, setSubgoalTitle] = useState("");
  const [showFullText, setShowFullText] = useState(false);

  useEffect(() => {
    if (!userId) return;

    const ddpRef = collection(db, `users/${userId}/ddps`);
    const q = query(ddpRef, limit(1));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const docSnapshot = querySnapshot.docs[0];
        const data = docSnapshot.data();
        setDdp({
          id: docSnapshot.id,
          ...data,
          createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
        });
      }
    });

    return () => unsubscribe();
  }, [userId]);

  const handleOpenEditModal = () => {
    setEditText(ddp.ddp);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleUpdateDdp = async () => {
    if (ddp) {
      try {
        // Subgoalsの削除
        const subGoalsRef = collection(
          db,
          `users/${userId}/ddps/${ddp.id}/subgoals`
        );
        const subGoalsSnapshot = await getDocs(subGoalsRef);
        const batch = writeBatch(db);
        subGoalsSnapshot.forEach((subGoalDoc) => {
          batch.delete(
            doc(db, `users/${userId}/ddps/${ddp.id}/subgoals`, subGoalDoc.id)
          );
        });
        await batch.commit();

        // DDPの更新
        await updateDoc(doc(db, `users/${userId}/ddps`, ddp.id), {
          ddp: editText,
        });

        setOpenSnackbar(true);
        setSnackbarMessage("DDPが更新されました");
        setOpenEditModal(false);
      } catch (error) {
        console.error("Error updating document: ", error);
        setOpenSnackbar(true);
        setSnackbarMessage("DDPの更新中にエラーが発生しました");
      }
    }
  };

  const handleOpenSubgoalDialog = () => {
    setOpenSubgoalDialog(true);
  };

  const handleCloseSubgoalDialog = () => {
    setOpenSubgoalDialog(false);
  };

  const handleEvent = async (eventType) => {
    setCelebrationMessage("初めてDDPの小目標が登録されました！");
    setIsOpen(true);
  };

  const handleAddSubgoal = async () => {
    const userSubGoalsRef = collection(
      db,
      `users/${userId}/ddps/${ddp.id}/subgoals`
    );
    const userDoc = doc(db, "users", auth.currentUser.uid);

    try {
      const querySnapshot = await getDocs(userSubGoalsRef);
      const subgoalCount = querySnapshot.size;
      const isFirstRegistration = querySnapshot.empty;

      const subgoalData = {
        title: subgoalTitle,
        description: subgoalText,
        order: subgoalCount,
        isCompleted: false,
        completionPercentage: 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        originalDDPID: ddp.id,
        segmentId: "",
      };

      const docRef = await addDoc(userSubGoalsRef, subgoalData);

      await updateDoc(docRef, {
        segmentId: docRef.id,
      });

      if (isFirstRegistration && !userDoc.isRegisterSubGoal) {
        await updateDoc(userDoc, {
          isRegisterSubGoal: true,
        });
        handleEvent("subGoalRegistration");
      }

      setSnackbarMessage("小目標を追加しました");
      setOpenSnackbar(true);
      setSubgoalTitle("");
      setSubgoalText("");
      handleCloseSubgoalDialog();
    } catch (error) {
      console.error("Error adding subgoal: ", error.message);
      setSnackbarMessage("小目標の追加中にエラーが発生しました");
      setOpenSnackbar(true);
    }
  };

  if (!ddp) return null;

  return (
    <>
      <div className="mt-2 p-4 border rounded-md bg-white flex flex-col ">
        <div className="flex justify-between w-full items-center">
          <Typography variant="h5">DDP</Typography>
          <div>
            <IconButton onClick={handleOpenEditModal}>
              <EditIcon />
            </IconButton>
          </div>
        </div>
        <Divider
          style={{ width: "100%", marginTop: "0.5rem", marginBottom: "0.5rem" }}
        />

        <div
          style={{
            maxHeight: showFullText ? "none" : "120px",
            overflow: "hidden",
            marginBottom: "1rem",
          }}
        >
          <Typography
            style={{
              wordBreak: "break-word",
              whiteSpace: "pre-wrap",
              textAlign: "left",
            }}
          >
            {ddp?.ddp || "DDPが存在しません"}
          </Typography>
        </div>
        {ddp?.ddp && ddp.ddp.length > 300 && (
          <Button onClick={() => setShowFullText(!showFullText)}>
            {showFullText ? "隠す" : "もっと見る"}
          </Button>
        )}
        <Divider
          style={{ width: "100%", marginTop: "0.5rem", marginBottom: "0.5rem" }}
        />
      </div>

      {/* <div className="relative">
        <div className="absolute w-full left-1/2 transform -translate-x-1/2 bg-white/70 backdrop-blur-sm p-4 rounded-md shadow-md max-w-md">
          <p className="text-gray-700 font-semibold">
            現在マスターマインドメンバーズのミッションは『他者のDDPを叶える』です。他者のDDPを叶える事に集中して頂く為、自身のDDPの編集・閲覧を一時的に制限しております。
          </p>
        </div>

        <div className="blur-sm">
          <div className="mt-2 p-4 border rounded-md bg-white/30 backdrop-blur-lg flex flex-col shadow-lg">
            <div className="flex justify-between w-full items-center">
              <Typography variant="h5" className="text-gray-800">
                DDP
              </Typography>
              <div>
                <IconButton onClick={handleOpenEditModal} disabled>
                  <EditIcon />
                </IconButton>
              </div>
            </div>
            <Divider className="w-full my-2" />

            <div
              className={`max-h-${
                showFullText ? "none" : "32"
              } overflow-hidden mb-4`}
            >
              <Typography className="break-words whitespace-pre-wrap text-left text-gray-700">
                {ddp?.ddp || "DDPが存在しません"}
              </Typography>
            </div>
            {ddp?.ddp && ddp.ddp.length > 300 && (
              <Button onClick={() => setShowFullText(!showFullText)} disabled>
                {showFullText ? "隠す" : "もっと見る"}
              </Button>
            )}
            <Divider className="w-full my-2" />
          </div>
        </div>
      </div> */}

      <Modal open={openEditModal} onClose={handleCloseEditModal}>
        <Box sx={modalStyle}>
          <Typography id="edit-modal-title" variant="h6" component="h2">
            DDPを編集
          </Typography>
          <Typography sx={{ mt: 2 }}>
            {/* DDPを編集すると各ステップもクリアされます。よろしいですか？ */}
            DDPを更新します。よろしいですか？
            <br />
            <Typography color="error" fontSize="small">
              注意：この作業は取り消せません。
            </Typography>
          </Typography>
          <TextField
            fullWidth
            multiline
            minRows={3}
            maxRows={10}
            style={{ overflowY: "auto", marginBottom: "10px" }}
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
          />
          <Button onClick={handleUpdateDdp} variant="contained" color="primary">
            更新
          </Button>
        </Box>
      </Modal>

      <Dialog open={openSubgoalDialog} onClose={handleCloseSubgoalDialog}>
        <DialogTitle>DDPの小目標を設定</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="subgoal-title"
            label="小目標のタイトル"
            type="text"
            fullWidth
            variant="standard"
            value={subgoalTitle}
            onChange={(e) => setSubgoalTitle(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            id="subgoal-description"
            label="小目標の説明"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={subgoalText}
            onChange={(e) => setSubgoalText(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSubgoalDialog} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={handleAddSubgoal}
            color="primary"
            autoFocus
            disabled={!subgoalTitle || !subgoalText}
          >
            追加
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <CelebrationAnimation
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        message={celebrationMessage}
      />
    </>
  );
};

export default DDPList;
