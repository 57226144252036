import React, { useState, useEffect } from "react";
import {
  List,
  Typography,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  DialogContentText,
  Box,
} from "@mui/material";
import { db, auth } from "../api/firebase";
import { collection, getDocs, updateDoc, deleteDoc, doc, getDoc } from "firebase/firestore";
import AnnouncementItem from "./AnnouncementItem";
import PostContent from "./PostContent";

const AnnouncementList = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [currentAnnouncement, setCurrentAnnouncement] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsAdmin(userData.isAdmin);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      const announcementsCol = collection(db, "announcements");
      const announcementSnapshot = await getDocs(announcementsCol);
      const announcementList = announcementSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
      setAnnouncements(announcementList);
    };

    fetchAnnouncements();
  }, []);

  // Visibilityの状態を更新する関数を追加
  const handleVisibilityChange = async (id, isVisible) => {
    const docRef = doc(db, "announcements", id);
    await updateDoc(docRef, { isVisible }); // FirestoreのisVisibleを更新
    setAnnouncements((prevAnnouncements) =>
      prevAnnouncements.map((announcement) =>
        announcement.id === id ? { ...announcement, isVisible } : announcement
      )
    );
  };

  const handleViewAnnouncement = (announcement) => {
    setCurrentAnnouncement(announcement);
    setViewOpen(true);
  };

  const handleViewClose = () => {
    setViewOpen(false);
    setCurrentAnnouncement(null);
  };

  const handleEditOpen = (announcement) => {
    setCurrentAnnouncement(announcement);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setCurrentAnnouncement(null);
  };

  const handleDeleteOpen = (announcement) => {
    setCurrentAnnouncement(announcement);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setCurrentAnnouncement(null);
  };

  const handleUpdateAnnouncement = async () => {
    if (currentAnnouncement) {
      const docRef = doc(db, "announcements", currentAnnouncement.id);
      await updateDoc(docRef, {
        title: currentAnnouncement.title,
        content: currentAnnouncement.content,
      });

      setAnnouncements((prev) =>
        prev.map((announcement) =>
          announcement.id === currentAnnouncement.id
            ? { ...announcement, ...currentAnnouncement }
            : announcement
        )
      );
      handleEditClose();
    }
  };

  const handleDeleteAnnouncement = async () => {
    if (currentAnnouncement) {
      const docRef = doc(db, "announcements", currentAnnouncement.id);
      await deleteDoc(docRef);

      setAnnouncements((prev) =>
        prev.filter((announcement) => announcement.id !== currentAnnouncement.id)
      );
      handleDeleteClose();
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div" style={{ marginBottom: "16px" }}>
          お知らせ一覧
        </Typography>
        <List>
          {announcements.map((announcement) => (
            <AnnouncementItem
              key={announcement.id}
              isAdmin={isAdmin}
              announcement={announcement}
              onView={handleViewAnnouncement}
              onEdit={handleEditOpen}
              onDelete={handleDeleteOpen}
              onVisibilityChange={handleVisibilityChange} // Visibilityの関数を渡す
            />
          ))}
        </List>

        {/* お知らせ詳細表示用のダイアログ */}
        <Dialog open={viewOpen} onClose={handleViewClose} maxWidth="md" fullWidth>
          <DialogTitle>{currentAnnouncement?.title}</DialogTitle>
          <DialogContent>
            <PostContent content={currentAnnouncement?.content || ""} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleViewClose} color="primary">
              閉じる
            </Button>
          </DialogActions>
        </Dialog>

        {/* 編集用のダイアログ */}
        <Dialog open={editOpen} onClose={handleEditClose} maxWidth="md" fullWidth>
          <DialogTitle>お知らせを編集</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="タイトル"
              type="text"
              fullWidth
              variant="outlined"
              value={currentAnnouncement?.title || ""}
              onChange={(e) =>
                setCurrentAnnouncement({
                  ...currentAnnouncement,
                  title: e.target.value,
                })
              }
            />
            <TextField
              margin="dense"
              label="内容"
              type="text"
              fullWidth
              variant="outlined"
              multiline
              rows={6}
              value={currentAnnouncement?.content || ""}
              onChange={(e) =>
                setCurrentAnnouncement({
                  ...currentAnnouncement,
                  content: e.target.value,
                })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="secondary">
              キャンセル
            </Button>
            <Button onClick={handleUpdateAnnouncement} color="primary">
              更新
            </Button>
          </DialogActions>
        </Dialog>

        {/* 削除確認のダイアログ */}
        <Dialog open={deleteOpen} onClose={handleDeleteClose}>
          <DialogTitle>お知らせの削除</DialogTitle>
          <DialogContent>
            <DialogContentText>
              本当にこのお知らせを削除しますか？ この操作は取り消せません。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose} color="secondary">
              キャンセル
            </Button>
            <Button onClick={handleDeleteAnnouncement} color="primary">
              削除
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default AnnouncementList;
