import React, { useEffect, useState } from "react";
import { ListItem, Typography, IconButton, Switch, Box, Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";

const AnnouncementItem = ({ announcement, onDelete, onEdit, onView, onVisibilityChange }) => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsAdmin(userData.isAdmin);
      }
    };

    fetchUserInfo();
  }, []);

  const handleEditClick = (e) => {
    e.stopPropagation(); // イベントの伝播を停止
    if (onEdit) {
      onEdit(announcement);
    }
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation(); // イベントの伝播を停止
    if (onDelete) {
      onDelete(announcement);
    }
  };

  const handleSwitchClick = (e) => {
    e.stopPropagation(); // イベントの伝播を停止
    if (onVisibilityChange) {
      onVisibilityChange(announcement.id, e.target.checked);
    }
  };

  const handleViewClick = (e) => {
    // e.target が Switch の場合、handleViewClick を実行しないようにする
    if (e.target.closest(".MuiSwitch-root")) {
      return; // スイッチクリック時は処理を中断
    }

    if (onView) {
      onView(announcement);
    }
  };

  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toLocaleString();
    } else if (date && date.toDate) {
      return date.toDate().toLocaleString();
    }
    return "";
  };

  if (!announcement) {
    return null;
  }

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 1,
        marginTop: 1,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
        boxShadow: 1,
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
          boxShadow: 6,
        },
      }}
    >
      <ListItem
        key={announcement.id}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '8px 16px' }}
        button
        onClick={handleViewClick}
      >
        <Box display="flex" alignItems="center" style={{ width: '100%', marginBottom: 4 }}>
          <Typography variant="body2" style={{ color: "blue", marginRight: 8 }}>
            {formatDate(announcement.createdAt)}
          </Typography>
          {isAdmin && (
            <>
              <Switch
                className="MuiSwitch-root" // Switch のクラスを追加してクリックターゲットを判断
                checked={announcement.isVisible}
                onChange={handleSwitchClick}
                color="primary"
                size="small"
              />
              <Typography style={{ marginRight: 8 }}>表示</Typography>
              <IconButton edge="end" aria-label="edit" onClick={handleEditClick} size="small">
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={handleDeleteClick} size="small">
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </Box>
        <Box>
          <Typography variant="subtitle1" style={{ fontWeight: "bold", wordBreak: 'break-word', width: '100%' }}>
            {announcement.title}
          </Typography>
        </Box>
      </ListItem>
    </Paper>
  );
};

export default AnnouncementItem;
