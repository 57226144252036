import React, { useEffect, useState } from "react";
import { Chat, Channel, MessageList, Window, Thread } from "stream-chat-react";
import { useStreamChatClient } from "../context/StreamChatProvider";
import CustomMessageInput from "./CustomMessageInput"; // カスタムコンポーネントをインポート
import { logChatPromiseExecution } from "stream-chat";
import { TypingIndicator } from "./TypingIndicator";

const GroupChatEx = ({ groupId }) => {
  const chatClient = useStreamChatClient();
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    const initChat = async () => {
      if (chatClient) {
        const newChannel = chatClient.channel("messaging", groupId, {
          name: `Group Chat ${groupId}`,
        });

        await newChannel.watch();
        setChannel(newChannel);
      }
    };
    initChat();
  }, [chatClient, groupId]);

  // メッセージ送信のハンドラ
  const overrideSubmitHandler = async (message) => {
    // console.log("送信するメッセージ内容 =>", message);
    // console.log("添付ファイル =>", message.attachments); // 添付ファイルの内容を確認

    if (
      !message.text.trim() &&
      (!message.attachments || message.attachments.length === 0)
    ) {
      console.error("空のメッセージや添付ファイルのみは送信できません");
      return;
    }

    try {
      if (channel) {
        const sendMessagePromise = channel.sendMessage(message);
        logChatPromiseExecution(sendMessagePromise, "メッセージ送信");
      }
    } catch (error) {
      console.error("メッセージ送信失敗:", error);
    }
  };

  const actions = ["delete", "edit", "react"];


  if (!chatClient || !channel) return <div>Loading chat...</div>;

  return (
    <Chat client={chatClient}>
      <Channel channel={channel}>
        <Window>
        <MessageList messageActions={actions}/>

          <div className="pl-5 py-2 bg-white">
            <TypingIndicator />
          </div>

          {/* カスタムのMessageInputコンポーネントを利用 */}
          <CustomMessageInput overrideSubmitHandler={overrideSubmitHandler} />
        </Window>
      </Channel>
    </Chat>
  );
};

export default GroupChatEx;
